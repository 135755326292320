import { Link } from 'gatsby'
import React, { useState } from 'react'
import styled from 'styled-components'
import { Banner, FormContainer, InnerBanner } from '../templates/blog/listing'
import Box from './box'
import Button from './button'
import Input from './starter-1.1.0/input'

const OperatorSubscriptionBar = ({ backPath = '', backName = '', className = '' }) => {
  const [signupSuccess, setSignupSuccess] = useState(false)

  const handleSubmit = event => {
    event.preventDefault()
    const el = event.target
    var formData = new FormData(el)
    fetch(
      'https://www.mailblast.io/lists/5e950c49-d812-4520-a494-f682e1acfb3e/subscribers/create_external',
      {
        method: 'POST',
        body: formData,
      }
    )
      .then(res => {
        if (res.status === 200) {
          setSignupSuccess(true)
        }
        return res.json()
      })
      .then(function (data) {
        if (data && data.error && data.error.length > 0) {
          alert(data.error[0])
        }
      })
  }
  return (
    <Banner className={'mt-80 pt-[25px] pb-[24px] tabletLToLower:mt-[57px]' + ' ' + className}>
      <InnerBanner>
        {backPath ? (
          <StyledLink to={backPath}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.5 15L7.5 10L12.5 5"
                stroke="white"
                stroke-width="1.25"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span className='ml-5'>
              {backName}
            </span>
          </StyledLink>
        ) : (
          <div />
        )}
        <FormContainer className='tabletLToLower:w-full'>
          {signupSuccess ? (
            <Box className={'tabletLToLower:px-16'}>
              <div className='text-white font-display text-sm leading-17 text-right'>
                Noice, you’re in!
              </div>
              <div className='font-display text-xxs leading-[15px] text-right mt-6' style={{ color: 'rgba(255, 255, 255, 0.6)' }}>Watch out for our first email</div>
            </Box>
          ) : (
            <>
              <Box className={'tabletLToLower:px-16'}>
                <div className='text-white font-display text-sm leading-17 text-right'>Become a better operator</div>
                <div className='font-display text-xxs leading-[15px] text-right mt-6' style={{ color: 'rgba(255, 255, 255, 0.6)' }}>
                  Weekly tips about operations and company building
                </div>
              </Box>
              <div className="ml-16 flex tabletLToLower:ml-0 tabletLToLower:mt-16">
                <form
                  method="post"
                  action="https://www.mailblast.io/lists/5e950c49-d812-4520-a494-f682e1acfb3e/subscribers/create_external"
                  noValidate="novalidate"
                  onSubmit={handleSubmit}
                  className='flex tabletLToLower:px-16 tabletLToLower:w-full tabletLToLower:justify-center'
                >
                  <div>
                    {/* <StyledInput
                          type="email"
                          name="subscriber[email]"
                          required
                          aria-required="true"
                          placeholder="Enter your email"
                        /> */}
                    <Input
                      type="email"
                      name="subscriber[email]"
                      required
                      aria-required="true"
                      placeholder="Enter your email"
                      darkMode={true}
                      className='min-w-[250px] tabletLToLower:min-w-102'
                    // disabled
                    />
                  </div>
                  {/* <div aria-hidden="true">
                        <input
                          type="text"
                          name="mb_5e950c49d8124520a494f682e1acfb3e"
                          tabIndex={-1}
                          defaultValue
                        />
                      </div> */}
                  <div>
                    <Button type="submit" className="button mb-subscribe ml-16" colorType='secondary'>
                      Subscribe
                    </Button>
                  </div>
                </form>
              </div>
            </>
          )}
        </FormContainer>
      </InnerBanner>
    </Banner>
  )
}

export default OperatorSubscriptionBar

const StyledLink = styled(Link)`
  font-family: ${props => props.theme.typography.fontFamilyInter};
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: #ffffff;
  text-decoration: none;
  align-self: flex-start;

  @media ${props => props.theme.device.tablet} {
    align-self: center;
  }

  @media ${props => props.theme.device.tabletLToLower} {
    padding-left: 16px;
    padding-bottom: 16px;
  }

  svg {
    margin-right: 4px;
    transition: 100ms ease;
    transform: translateX(0);
  }

  :hover,
  :focus {
    svg {
      transform: translateX(-2px);
    }
  }
`