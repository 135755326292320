import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import Fuse from 'fuse.js'
import BlogCard from '../components/blogCard'
import SearchSvg from '../icons/search'
import config from '../../data/SiteConfig'

const Container = styled.div`
  padding-top: 4rem;
  padding-bottom: 4rem;
  width: 100%;
`

const H1 = styled.h1`
  font-family: ${props => props.theme.typography.fontFamilyGTBold};
  font-style: normal;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.8125rem;
  letter-spacing: 1px;
  margin: 0;
`

// const List = styled.div`
//   display: grid;
//   grid-template-columns: 
//   width: 100%;

//   @media ${props => props.theme.device.tablet} {
//     flex-direction: row;
//   }
// `

const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 3.4375rem;
  align-items: center;
`

const Search = styled.div`
  background: #f6f6f6;
  border-radius: 6.25rem;
  width: 16.25rem;
  display: flex;
  align-items: center;
  padding: 10px 16px;
  box-sizing: border-box;

  svg {
    margin-right: 0.5rem;
  }
`

const SearchInput = styled.input`
  background: none;
  border: none;
  width: 100%;
  height: 100%;
  outline: none;
  font-family: ${props => props.theme.typography.fontFamilyGTMono};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
`

const SearchResults = styled.div`
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 1.25rem;
  color: #000000;

  .search-value {
    font-family: ${props => props.theme.typography.fontFamilySuisseIntl};
    font-size: 1.5rem;
  }
`

const NoResults = styled.div`
  font-family: ${props => props.theme.typography.fontFamilySuisseIntl};
  font-style: normal;
  font-weight: bold;
  font-size: 1.625rem;
  line-height: 1.9375rem;
  letter-spacing: 1px;
  color: #000000;
  width: 100%;
  justify-content: center;
  text-align: center;
  margin-top: 48px;
`

const BlogList = ({ data }) => {
  const [searchValue, setSearchValue] = useState('')
  const [blogs, setBlogs] = useState(data.allMarkdownRemark.edges)

  const fuse = useRef(
    new Fuse(data.allMarkdownRemark.edges, {
      keys: [
        'node.frontmatter.title',
        'node.frontmatter.author.frontmatter.firstName',
        'node.frontmatter.author.frontmatter.lastName',
        'node.excerpt',
      ],
    })
  )

  const handleSearchChange = e => {
    setSearchValue(e.target.value)

    if (!e.target.value) {
      setBlogs(data.allMarkdownRemark.edges)
    } else {
      const res = fuse.current.search(e.target.value || '')
      setBlogs(res.map(r => r.item))
    }
  }

  return (
    <Container>
      <div className='flex justify-between max-w-[1054px] m-auto'>
        {searchValue ? (
          <SearchResults>
            <div>
              Search Results for:{' '}
              <span className="search-value">{searchValue}</span>
            </div>
          </SearchResults>
        ) : (
          <div
            className='font-display text-3xl leading-36'
          >Blog</div>
        )}
        <Search>
          <SearchSvg />
          <SearchInput onChange={handleSearchChange} placeholder="Search blog..." />
        </Search>
      </div>
      <>
        {blogs.length === 0 ? (
          <NoResults>Sorry, no results were found.</NoResults>
        ) : (
          <div className='mt-24 grid grid-cols-3 w-[1054px] gap-x-32 gap-y-24 mx-auto tabletLToLower:grid-cols-1 tabletLToLower:w-full'>
            {
              blogs.map(item => {
                const { node } = item
                const {
                  frontmatter: { title, author, date, cover, slug },
                  excerpt,
                  id,
                } = node
                const {
                  frontmatter: { firstName, lastName, cover: avatar },
                } = author

                const postType = 'blog'
                const postPrefix = config[`${postType}Prefix`] + '/'
                const to = postPrefix + slug + '/'

                return (
                  <BlogCard
                    key={id}
                    to={to}
                    title={title}
                    avatar={avatar}
                    firstName={firstName}
                    lastName={lastName}
                    date={date}
                    cover={cover}
                    excerpt={excerpt}
                  />
                )
              })
            }
          </div>
        )}
      </>
    </Container>
  )
}

export default BlogList
