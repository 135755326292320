import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Box from './box'

const StyledLink = styled(Link)`
  text-decoration: unset;
  color: unset;

  &:hover {
    .lower-section {
      .read-label {
        transform: translateX(0rem);
        opacity: 1;
      }
    }
  }
  @media ${props => props.theme.device.tabletLToLower} {
    display: flex;
    justify-content: center;
  }
`

const Article = styled.article`
  width: 330px;
  height: 360px;
  box-shadow: ${props => props.theme.shadows[2]};
  // margin-right: 2rem;
  // margin-bottom: 2rem;
  border-radius: 0.875rem 0.875rem;
  display: flex;
  flex-direction: column;

  .upper-section {
    height: 148px;
    overflow: hidden;

    img {
      border-top-left-radius: 0.875rem;
      border-top-right-radius: 0.875rem;
    }
  }

  .middle-section {
    width: 100%;
    box-sizing: border-box;
    height: 172px;
    padding: 24px 24px 12px 24px;
    // flex: 1;
    max-height: 10.75rem;
    overflow: hidden;
  }

  .lower-section {
    width: 100%;
    height: 40px;
    position: relative;
    overflow: hidden;

    .label {
      padding-left: 1.5rem;
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 1rem;
      text-transform: uppercase;
      color: #CBCBCB;
    }

    .read-label {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #FFFFFF;
      padding: 10px 25px 10px 39px;
      background: #000000;
      border-radius: 0.875rem 0rem;
      position: absolute;
      right: 0;
      transition: all 200ms ease;
      opacity: 0;
      transform: translateX(100%);
    }
  }
`

const Title = styled.h3`
  font-family: ${props => props.theme.typography.fontFamilyInter};
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: ${props => props.theme.core.content.primary};
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

const Excerpt = styled.span`
  font-family: ${props => props.theme.typography.fontFamilyInter};
  font-style: normal;
  font-weight: normal;
  width: 100%;
  color: ${props => props.theme.core.content.secondary};
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`

const BlogCard = ({ to, title, cover, excerpt, date }) => (
  <StyledLink to={to}>
    <Article>
      {cover && (
        <div className="upper-section">
          <Img
            fluid={cover.childImageSharp.fluid}
            alt={title}
            style={{
              maxWidth: '100%',
              height: '148px',
            }}
            imgStyle={{
              objectPosition: 'top',
            }}
          />
        </div>
      )}
      <div className="p-24 flex-1">
        <div className='font-semibold text-lg leading-24 text-black'>
          {title}
        </div>
        <Excerpt className='mt-12 text-sm leading-20'>{excerpt}</Excerpt>
      </div>
      <div className="lower-section">
        <span className="text-sm leading-24 pl-24" style={{ color: 'rgba(203, 203, 203, 1)' }}>{date}</span>
        <span className="read-label">Read -&gt;</span>
      </div>
    </Article>
  </StyledLink>
)

export default BlogCard
