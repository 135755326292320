import styled from 'styled-components'

const RowLayout = styled.section`
  display: flex;
  padding-left: ${props => props.theme.spacing(2)};
  padding-right: ${props => props.theme.spacing(2)};
  overflow-x: hidden;
  max-width: 100vw;
  box-sizing: border-box;
  flex-direction: column;

  @media (min-width: 1160px) {
    padding-left: ${props => props.theme.spacing(10)};
    padding-right: ${props => props.theme.spacing(10)};
    flex-direction: row;
  }

  @media (min-width: 1200px) {
    padding-left: calc(50vw + 2.5rem - 37.5rem);
    padding-right: calc(50vw + 2.5rem - 37.5rem);
    flex-direction: row;
  }
`

export default RowLayout
