import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.167 14.375a5.208 5.208 0 110-10.417 5.208 5.208 0 010 10.417v0z"
        stroke="#000"
        strokeWidth={2.118}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.082 12.585a1.059 1.059 0 10-1.497 1.497l1.497-1.497zm2.253 5.247l.748.749 1.498-1.498-.749-.748-1.497 1.497zm-3.75-3.75l3.75 3.75 1.497-1.497-3.75-3.75-1.497 1.497z"
        fill="#000"
      />
    </svg>
  )
}

export default SvgComponent
