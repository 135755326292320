import React, { useState } from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'

import Nav from '../../components/nav'
import SEO from '../../components/seo'
import Box from '../../components/box'
import Footer from '../../components/footer'
import RowLayout from '../../components/rowLayout'
import BlogList from '../../containers/blogList'
import { useRemoveHotjarScript } from '../../utils/hooks/useHotjarScript'
import Button from '../../components/button'
import Input from '../../components/starter-1.1.0/input'
import OperatorSubscriptionBar from '../../components/operatorSubscriptionBar'

const BlogListing = ({ data }) => {
  useRemoveHotjarScript()

  return (
    <>
      <SEO
        title="Pulse Blog - Weekly tips on operations and company building"
        description="The Pulse blog is here to level up your skills as a leader managing a remote company with tips on operations, internal communication, and team building. "
        ogImage="/assets/ogimages/pulse-blog.png"
        twImage="/assets/ogimages/twitter/pulse-blog.png"
      />
      <Nav style={{
        background: '#FFFFFF',
        position: 'absolute'
      }}
        nonStick
      />
      <OperatorSubscriptionBar />
      <RowLayout style={{ background: 'none' }}>
        <BlogList data={data} />
      </RowLayout>
      <Footer />
    </>
  )
}

export default BlogListing

export const blogListingQuery = graphql`
  query BlogListingQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [fields___date], order: DESC }
      filter: { fileAbsolutePath: { regex: "/(content/blog/).*\\\\.md$/" } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          fields {
            slug
            date
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            cover {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            date(formatString: "MMMM Do, YYYY")
            slug
            author {
              frontmatter {
                firstName
                lastName
                cover {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                slug
                email
                twitterHandle
                bio
              }
            }
          }
        }
      }
    }
  }
`

const StyledInput = styled.input`
  background: #444444;
  /* Shadows/Default */

  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  font-family: Suisse Intl;
  font-size: 16px;
  line-height: 20px;
  padding: 10px 16px;
`

export const Banner = styled.div`
  min-height: 5.5rem;
  height: auto;
  max-width: 100vw;
  max-height: max-content;
  // background-color: ${props => props.theme.core.background.inverseSecondary};
  background: #111111;
  align-items: center;
  box-sizing: border-box;
  overflow: hidden;
  // padding-left: ${props => props.theme.spacing(2)};
  // padding-right: ${props => props.theme.spacing(2)};
  // padding-bottom: ${props => props.theme.spacing(2)};
  // padding-top: ${props => props.theme.spacing(2)};
  display: flex;

  // @media ${props => props.theme.device.tablet} {
  //   padding-top: 0;
  //   padding-bottom: 0;
  // }

  @media (min-width: 1160px) {
    padding-left: ${props => props.theme.spacing(10)};
    padding-right: ${props => props.theme.spacing(10)};
  }
`

export const InnerBanner = styled.div`
  max-width: 75rem;
  margin: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

  @media ${props => props.theme.device.laptop} {
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }
`

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${props => props.theme.device.laptop} {
    flex-direction: row;
  }

  .form-copy {
    margin-right: 0.75rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 1rem;

    @media ${props => props.theme.device.tablet} {
      margin-bottom: 0;
    }
  }

  #form-header {
    font-family: ${props => props.theme.typography.fontFamilyInter};
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1rem;
    display: flex;
    align-items: center;
    text-align: right;
    color: ${props => props.theme.colors.purple[50]};
    margin-top: 0.375rem;
    margin-bottom: 0.375rem;
  }

  #form-subtext {
    font-family: ${props => props.theme.typography.fontFamilyGTMono};
    font-style: normal;
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 0.875rem;
    text-align: right;
    color: rgba(255, 255, 255, 0.6);
  }

  .mb-embed {
    display: flex;
  }

  .mb-embed .mb-form-group input {
    background: #ffffff;
    border: 3px solid #b0ed6a;
    box-sizing: border-box;
    width: 14.5625rem;
    height: 3rem;
    padding: 0.625rem 1rem;
    box-sizing: border-box;
    font-family: ${props => props.theme.typography.fontFamilyGTMono};
    font-size: 0.875rem;
    line-height: 1.75rem;
    font-style: normal;
    font-weight: normal;
    outline: none;
    color: rgba(0, 0, 0, 1);
    border-radius: 0px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    ::placeholder {
      color: rgba(0, 0, 0, 0.5);
    }
  }

  .mb-embed .mb-form-group button {
    width: 9.6875rem;
    height: 3rem;
    background: #b0ed6a;
    font-family: ${props => props.theme.typography.fontFamilyGT};
    font-style: normal;
    font-weight: 900;
    font-size: 1rem;
    line-height: 1rem;
    text-transform: capitalize;
    color: #000000;
    border: 3px solid #b0ed6a;
    cursor: pointer;

    :hover {
      opacity: 0.9;
    }
  }

  .mb-embed .mb-form-group-tk {
    position: absolute;
    left: -5000px;
  }

  .mb-form {
    display: flex;
  }
`
